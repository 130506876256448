import React, { useEffect, useRef, useState } from "react";
import { motion, AnimatePresence } from "framer-motion";
import PropTypes from "prop-types";
import clsx from "clsx";
import { useAnimationContext } from "../../context/AnimationContext";

const durations = {
    animate: 0.25,
    exit: 0.25,
};

const variants = {
    initial: { y: 10, opacity: 0 }, // New number enters from above
    animate: { y: 0, opacity: 1, transition: { duration: durations.animate, type: "spring" } }, // New number moves into place
    exit: { y: -10, opacity: 0, transition: { duration: durations.exit } }, // Current number exits downwards
};

const NumberRoller = ({ targetNumber, animationKey, customClasses = "" }) => {
    const [currentNumber, setCurrentNumber] = useState(targetNumber);
    const { registerAnimation } = useAnimationContext(); // Get animation context
    let resolveAnimation = useRef();

    /**
     * Controls for this animation component
     */
    const animationControls = {
        start: async (newTarget) => {
            return new Promise((resolve) => {
                setCurrentNumber(newTarget);
                resolveAnimation.current = { resolve };
            });
        },
    };

    // Register this animation with the AnimationContext
    useEffect(() => {
        if (animationKey) {
            registerAnimation(animationKey, animationControls);
        }
    }, [animationKey, registerAnimation]); // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <div
            className={clsx(
                "tw-relative tw-inline-block tw-h-[1rem] tw-w-full tw-leading-[1rem] tw-overflow-hidden",
                customClasses
            )}
        >
            <AnimatePresence mode="wait">
                <motion.div
                    className="tw-absolute tw-w-full"
                    key={currentNumber} // Key should be unique to the current number
                    initial="initial"
                    animate="animate"
                    exit="exit"
                    variants={variants}
                    onAnimationComplete={(animationVariant) => {
                        const targetNumberAnimationEnd =
                            currentNumber === targetNumber && animationVariant === "animate";

                        // Resolve the current animation when the target number animation has ended
                        if (resolveAnimation?.current?.resolve && targetNumberAnimationEnd) {
                            resolveAnimation.current.resolve();
                            resolveAnimation.current.resolve = null;
                        }
                    }}
                >
                    {currentNumber}
                </motion.div>
            </AnimatePresence>
        </div>
    );
};

NumberRoller.propTypes = {
    targetNumber: PropTypes.number.isRequired,
    animationKey: PropTypes.string.isRequired, // Unique key for this animation
    customClasses: PropTypes.string,
};

export default NumberRoller;
