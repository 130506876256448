import React, { useEffect, useState } from "react";
import { motion, useAnimation } from "framer-motion";
import PropTypes from "prop-types";

import { formatPrice } from "../../helpers/helpers";

/**
 * A component that animates a number from 0 to the provided value with an optional shake effect.
 * The number is formatted as a price (e.g., "$1,234").
 *
 * @param {Object} props - The props for the component.
 * @param {number} props.value - The target number to animate to (e.g., 1234).
 * @param {string} [props.className] - Optional CSS class name to apply to the component.
 * @param {boolean} [props.shake=false] - Whether to apply a shake effect after the animation completes.
 *
 * @returns {React.Element} The rendered `motion.span` element with the animated number.
 */
const AnimatedNumber = ({ value, className, shake = false, start = true }) => {
    const controls = useAnimation();
    const [displayValue, setDisplayValue] = useState(0);
    const [hasAnimated, setHasAnimated] = useState(false);

    useEffect(() => {
        // Check if animation should run only once it is started
        if (!hasAnimated && start) {
            const animation = controls.start({
                value: [0, value],
                transition: { duration: 1, ease: "easeOut" },
            });

            const postAnimation = () => {
                setTimeout(() => {
                    setDisplayValue(value);
                    setHasAnimated(true); // Mark as animated
                }, 500);
            };

            // When the number animation completes, trigger the shake if enabled
            animation.then(() => {
                if (shake) {
                    // Start a horizontal shake animation
                    controls
                        .start({
                            x: [0, -5, 5, -5, 5, 0], // Shake keyframes
                            transition: { duration: 0.5, ease: "easeInOut" },
                        })
                        .then(() => postAnimation); // Add a bit of delay after the shake animation
                } else {
                    postAnimation();
                }
            });
        }

        // Cleanup function to stop animation if the component is unmounted
        return () => controls.stop();
    }, [value, shake, controls, hasAnimated, start]);

    return (
        <motion.span
            className={className}
            animate={controls}
            initial={{ value: 0 }}
            onUpdate={(latest) => setDisplayValue(latest.value)} // Update displayValue on each frame
        >
            {formatPrice(displayValue)}
        </motion.span>
    );
};

AnimatedNumber.propTypes = {
    value: PropTypes.number.isRequired,
    className: PropTypes.string,
    shake: PropTypes.bool,
    start: PropTypes.bool,
};

export default AnimatedNumber;
