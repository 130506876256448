import React, { useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import { useQuery } from "@tanstack/react-query";
import { get, isEmpty, isNil } from "lodash";
import { motion } from "framer-motion";
import Sticky from "react-sticky-el";
import MoonLoader from "react-spinners/MoonLoader";
import moment from "moment-timezone";

import config from "../../../../config";
import PropertyInfo from "../../../../components/common/PropertyInfo";
import DailyScoreModal from "../../../../components/modals/LiveVariant/DailyScoreModal";

import { LIVE_VARIANT_VERSIONS } from "../../../../constants/live-variant-versions";

import { getLiveVariantDailyProperty, getNearestProperty } from "../../../../services/PropertyService";
import { formatPrice, wait } from "../../../../helpers/helpers";
import { triggerShare } from "../../../../helpers/triggerShare";
import useGAEvent from "../../../../hooks/useGAEvent";
import useLocalStorage from "../../../../hooks/useLocalStorage";
import useModalState from "../../../../hooks/useModalState";

import Buttons from "./Buttons";
import NumberRoller from "../../../../components/animations/NumberRoller";
import { useAnimationContext } from "../../../../context/AnimationContext";

const THRESHOLD = 5;
const INITIAL_LIMIT = 10;

const slideVariants = {
    hidden: { y: 0, opacity: 1 }, // Start position
    slideUp: ({ heightOffset }) => ({ y: `-${heightOffset}px`, opacity: 1, transition: { duration: 0.5 } }), // Slide up animation // TODO: Get the height of the propertyinfo component + its price below
    slideIn: { y: 0, opacity: 1, transition: { duration: 0 } }, // End
};

const containerVariants = {
    animate: {
        transition: {
            staggerChildren: 0.5, // Adjust delay between child animations
        },
    },
};

/**
 * Live variant game version component that allows users to guess whether the price of Property A is higher or lower than of property B.
 *
 * @component
 * @param {object} props - The component props.
 * @param {string} props.version - The version of the live variant game.
 * @returns {JSX.Element} The live variant game interface.
 */
export default function Flip({ version }) {
    const { startAnimation } = useAnimationContext();
    const bodyRef = useRef(null);
    const propertyARef = useRef(null);
    const [heightOffset, setHeightOffset] = useState(0);
    const [points, setPoints] = useState(0);
    const [yesterdaysProperty, setYesterdaysProperty] = useState(null);
    const [todaysProperty, setTodaysProperty] = useState(null);
    const [nearestProperties, setNearestProperties] = useState([]);
    const [properties, setProperties] = useState([]);
    const [showModal, setShowModal] = useState(false);
    const [isSliding, setIsSliding] = useState(false);
    const [bgColor, setBgColor] = useState(null);
    const [clickedButton, setClickedButton] = useState(null);
    const [isCorrect, setIsCorrect] = useState(null);
    const { setModalId } = useModalState();
    const { sendEvent } = useGAEvent();
    const [v3FlipState, setV3FlipState] = useLocalStorage("v3-flip-state");
    const [shownHelpModals, setShownHelpModal] = useLocalStorage("shown-help-modals", []);
    const [guessedProperties, setGuessedProperties] = useLocalStorage("guessed_properties", []);
    const [visitorStatus] = useLocalStorage("visitor-status");
    const currentVersion = LIVE_VARIANT_VERSIONS[version];
    const isNewGame = !v3FlipState || v3FlipState?.status === "new_game";
    const isPropertyGuessed = v3FlipState?.status === "game_end";
    const yesterdayDate = isNewGame
        ? moment().tz("America/Chicago").subtract(1, "days").format("YYYY-MM-DD")
        : "";

    // Yesterday's daily property
    useQuery({
        queryKey: ["live-variant-yesterdays-daily-property", yesterdayDate],
        queryFn: () => getLiveVariantDailyProperty(yesterdayDate),
        enabled: isNewGame && !isPropertyGuessed,
        cacheTime: 0,
        onSuccess: (data) => {
            const property = get(data, "data.properties", null);
            if (property?.price) {
                setYesterdaysProperty(property);
            }
        },
    });

    // Today's daily property
    const propertyB = useQuery({
        queryKey: ["live-variant-daily-property"],
        queryFn: getLiveVariantDailyProperty,
        enabled: true,
        cacheTime: 0,
        onSuccess: (data) => {
            const property = get(data, "data.properties", null);
            if (property?.price) {
                setTodaysProperty(property);
            }
        },
    });

    const location = todaysProperty?.street_address.split(",")[0];

    // Nearest properties
    // Based on user's previous location setting, if available or
    // Based on location of property B, if user does not have a previous location setting
    const propertyC = useQuery({
        queryKey: ["live-variant-nearest-properties", location, guessedProperties, INITIAL_LIMIT],
        queryFn: () => getNearestProperty({ location, guessedProperties, limit: INITIAL_LIMIT }),
        enabled: !isEmpty(location) && nearestProperties.length < THRESHOLD && !isPropertyGuessed,
        cacheTime: 0,
        onSuccess: (data) => {
            const extraProperties = get(data, "data.properties", null);
            if (extraProperties && extraProperties.length) {
                setProperties([...properties, extraProperties[0]].filter((property) => !isNil(property)));
                setNearestProperties(extraProperties.slice(1));
            }
        },
    });

    const isLoading = properties.length < 3;
    const propertyAPrice = properties[0]?.price;
    const propertyBPrice = properties[1]?.price;

    const animateToColor = (color, duration = 1500) => {
        return new Promise(async (resolve) => {
            setBgColor(null);
            await wait(duration);
            setBgColor(color);
            await wait(isCorrect ? 1000 : 1500);
            setBgColor(null);
            setClickedButton(null);
            resolve();
        });
    };

    const animateSlideUp = (duration = 1000) => {
        return new Promise(async (resolve) => {
            setIsSliding(true);
            await wait(duration);
            setIsSliding(false);
            resolve();
        });
    };

    /**
     * Updates the points value by applying an adjustment and triggers an animation.
     *
     * - Adjusts the current points by adding the given adjustment value.
     * - Updates the points state and starts an animation to display the change.
     *
     * @param {number} adjustment - The value to adjust the current points by. Can be positive or negative.
     * @returns {Promise<void>} Resolves when the animation completes.
     *
     * @example
     * // Increase points by 5
     * await updatePoints(5);
     *
     * // Decrease points by 3
     * await updatePoints(-3);
     */
    const updatePoints = async (adjustment) => {
        const newPoints = points + adjustment;

        setPoints(newPoints);
        await startAnimation("flip-number-roller", newPoints);
    };

    /**
     * Handles the user's guess for whether the price is higher or lower.
     *
     * @param {string} buttonType - The type of guess ("lower" or "higher").
     */
    const handleClick = (buttonType) => {
        setClickedButton(buttonType);
        setGuessedProperties([...guessedProperties, properties[1]?.mls_id]);
        sendEvent("enter_guess", {
            liveVariantVersion: currentVersion?.gaVersion,
            visitorStatus,
            userScore: points,
        });

        const isCorrect =
            (buttonType === "lower" && propertyBPrice < propertyAPrice) ||
            (buttonType === "higher" && propertyBPrice > propertyAPrice);

        setIsCorrect(isCorrect);
        setHeightOffset(propertyARef.current.clientHeight);

        if (isCorrect) {
            // Move previous Property B to Property A and add new Property B
            const newSet = [properties[1], nearestProperties[0], nearestProperties[1]].filter(
                (property) => !isNil(property)
            );

            // Animation sequence
            (async () => {
                await animateToColor("#7CC836", 2000); // Correct button color

                // Update points
                await updatePoints(1);

                await animateSlideUp(); // Slide up animation
                setProperties(newSet); // Update properties
                await wait(1000);
                setNearestProperties(nearestProperties.slice(1)); // Update nearest properties
                setV3FlipState({
                    dailyPropertyMlsId: todaysProperty.mls_id,
                    points: points + 1,
                    properties: newSet,
                    status: "game_inprogress",
                });
            })();
        } else {
            // Handle incorrect guess
            (async () => {
                await animateToColor("#FF0000"); // Wrong button color
                setV3FlipState({
                    dailyPropertyMlsId: todaysProperty.mls_id,
                    points,
                    properties,
                    status: "game_end",
                });
                await wait(500);
                setShowModal("dailyscore");
            })();
        }
    };

    const handleShare = async () => {
        const score = v3FlipState?.points || points;

        sendEvent("daily_score_share_score", {
            userScore: score,
            liveVariantVersion: currentVersion?.gaVersion,
            visitorStatus,
        });

        const currentDate = moment().tz("America/Chicago").format("MMM D, YYYY");
        const shareTextTitle = `PriceMe Total Score: ${score ?? 0}\n`;
        const shareTextBody = `${currentDate}`;
        const shareUrl = `${config.LIVE_VARIANT_BASE_URL}/${currentVersion.url}?utm_medium=share`;

        await triggerShare({
            web: {
                text: `${shareTextTitle}${shareTextBody}\n\n${shareUrl}`,
            },
            mobile: {
                title: shareTextTitle,
                text: shareTextBody,
                url: shareUrl,
            },
        });
    };

    useEffect(() => {
        sendEvent("start_page_load", {
            liveVariantVersion: currentVersion?.gaVersion,
            visitorStatus,
        });
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (isEmpty(properties) && yesterdaysProperty && todaysProperty) {
            const newGameProperties = [yesterdaysProperty, todaysProperty].filter(
                (property) => !isNil(property)
            );
            setProperties(newGameProperties);

            setV3FlipState({
                dailyPropertyMlsId: todaysProperty.mls_id,
                points: 0,
                properties: newGameProperties,
                status: "new_game",
            });
        }
    }, [properties, yesterdaysProperty, todaysProperty]); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (
            v3FlipState?.dailyPropertyMlsId &&
            propertyB?.mls_id &&
            v3FlipState.dailyPropertyMlsId !== propertyB.mls_id
        ) {
            // reset v3FlipState
            setV3FlipState({
                dailyPropertyMlsId: propertyB.mls_id,
                points: 0,
                properties: [],
                status: "new_game",
            });
        }
    }, [v3FlipState?.dailyPropertyMlsId, propertyB?.mls_id]); // eslint-disable-line react-hooks/exhaustive-deps

    // Fetch more properties when threshold is met
    useEffect(() => {
        if (nearestProperties.length < THRESHOLD) {
            propertyC.refetch(); // Trigger background fetch for additional properties
        }
    }, [nearestProperties.length, properties]); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (!isEmpty(properties) && !isEmpty(nearestProperties) && properties.length < 3) {
            setProperties([...properties, nearestProperties[0]].filter((property) => !isNil(property)));
            setNearestProperties(nearestProperties.slice(1));
        }
    }, [properties.length]); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (!isLoading && !shownHelpModals?.includes(version)) {
            setShownHelpModal([...shownHelpModals, version]);
            setModalId("LIVE_VARIANT_HELP_MODAL");
        }
    }, [isLoading, shownHelpModals]); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (isPropertyGuessed && v3FlipState?.points && v3FlipState.points !== points) {
            setPoints(v3FlipState.points);
        }
    }, [v3FlipState?.points]); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if (isPropertyGuessed && v3FlipState?.properties && v3FlipState.properties !== properties) {
            setProperties(v3FlipState.properties.filter((property) => !isNil(property)));
        }
    }, [v3FlipState?.properties]); // eslint-disable-line react-hooks/exhaustive-deps

    if (propertyB?.error) {
        return (
            <div className="tw-flex tw-items-center tw-justify-center tw-h-3/4 tw-p-8">
                {propertyB.error.response?.data?.message ||
                    "Failed to load daily property. Please try again later."}
            </div>
        );
    }

    return isLoading ? (
        <div className="tw-flex tw-items-center tw-justify-center tw-h-3/4">
            <MoonLoader size={40} color="#63c19f" />
        </div>
    ) : (
        <motion.div
            className="property-container"
            ref={bodyRef}
            variants={containerVariants}
            initial="hidden"
            animate="animate"
        >
            {properties.map((property, index) => {
                if ((index === 2 && !isSliding) || !property) return null;

                return (
                    <>
                        <motion.div
                            initial="hidden"
                            animate={isSliding ? "slideUp" : "slideIn"}
                            variants={slideVariants}
                            custom={{
                                heightOffset,
                            }}
                            ref={index === 0 ? propertyARef : null}
                        >
                            <PropertyInfo property={property} showMoreDetails={false} index={index} />
                            {index === 0 || index === 2 ? (
                                <>
                                    <div className="!tw-mb-[-25px] tw-flex tw-justify-center">
                                        <div className="tw-flex tw-justify-center tw-items-center tw-pb-4 tw-pt-2 tw-bg-[#7CC836] tw-h-[65px] tw-max-w-[600px] tw-w-full tw-border-b-black tw-border-b-[15px]">
                                            <span className="tw-font-noto tw-text-[28px] tw-font-bold">
                                                {formatPrice(property?.price)}
                                            </span>
                                        </div>
                                    </div>
                                    <div className="versus-icon-container">
                                        <span className="versus-icon">VS</span>
                                    </div>
                                    <br />
                                </>
                            ) : null}
                        </motion.div>
                    </>
                );
            })}

            {}
            <Sticky
                className="tw-sticky tw-bottom-0 tw-z-10 tw-w-full"
                mode="bottom"
                positionRecheckInterval={50}
            >
                <div className="tw-max-w-[600px] tw-w-full tw-flex tw-justify-center tw-items-center mx-auto">
                    {isPropertyGuessed ? (
                        <div className="tw-bg-[#FF0000] tw-w-full tw-h-[60px] tw-flex tw-justify-center tw-items-center">
                            <span className="tw-text-black tw-font-noto tw-text-[28px] tw-font-bold">
                                {formatPrice(v3FlipState?.properties[1]?.price)}
                            </span>
                        </div>
                    ) : (
                        <>
                            <Buttons
                                clickedButton={clickedButton}
                                bgColor={bgColor}
                                onClick={handleClick}
                                actualPrice={propertyBPrice}
                                isCorrect={isCorrect}
                            />
                            <div className="tw-w-[14%] tw-h-[60px] tw-bg-black tw-flex tw-justify-center tw-items-center">
                                <span className="tw-text-white tw-font-noto !tw-text-[28px] tw-font-bold tw-w-full tw-text-center">
                                    <NumberRoller
                                        animationKey="flip-number-roller"
                                        targetNumber={points}
                                        customClasses="!tw-h-[1.5rem] !tw-leading-[1.5rem]"
                                    />
                                </span>
                            </div>
                        </>
                    )}
                </div>
            </Sticky>
            {showModal === "dailyscore" && (
                <DailyScoreModal
                    show={showModal === "dailyscore"}
                    handleClose={() => setShowModal("done")}
                    score={v3FlipState?.points || points}
                    actualPrice={formatPrice(v3FlipState?.properties[1]?.price)}
                    version={currentVersion}
                    property={properties[0]}
                    handleShare={handleShare}
                />
            )}
        </motion.div>
    );
}

Flip.propTypes = {
    version: PropTypes.string.isRequired,
};
