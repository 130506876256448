import ChooseGame from "../pages/ChooseGame";
import OverUnder from "../pages/LiveVariant/components/OverUnder";
import Flip from "../pages/LiveVariant/components/Flip";
import Puzzle from "../pages/LiveVariant/components/Puzzle";

import OverUnderIcon from "../assets/icons-v2/over-under.png";
import FlipIcon from "../assets/icons-v2/flip.png";
import HiddenInfoIcon from "../assets/icons-v2/hidden-info.png";
import PuzzleIcon from "../assets/icons-v2/puzzle.png";

import OverUnderModalBody from "../components/modals/LiveVariant/HelpModal/components/OverUnderModalBody";
import FlipModalBody from "../components/modals/LiveVariant/HelpModal/components/FlipModalBody";
import PuzzleModalBody from "../components/modals/LiveVariant/HelpModal/components/PuzzleModalBody";

export const LIVE_VARIANT_VERSIONS = {
    v0: {
        url: "choose-game",
        component: <ChooseGame />,
        disabled: true,
    },
    v1: {
        url: "over-under",
        component: <OverUnder version="v1" />,
        gaVersion: "V1 - Over/Under",
        title: "PriceMe Over/Under",
        description: "Guess if the actual price is higher or lower than the guess price",
        icon: {
            src: OverUnderIcon,
            width: 70,
            height: 70,
        },
        modal: {
            body: <OverUnderModalBody />,
            show_actual_price: true,
        },
        variance: {
            start: 0.6,
            end: 0.7,
        },
        max_tries: 3,
    },
    v3: {
        url: "flip",
        component: <Flip version="v3" />,
        title: "PriceMe More or Less",
        description: "Compare two properties and guess which one sold for more",
        icon: {
            src: FlipIcon,
            width: 65,
            height: 65,
        },
        modal: {
            body: <FlipModalBody />,
            show_actual_price: true,
        },
        disabled: false,
    },
    v4: {
        url: "hidden-info",
        component: null,
        title: "PriceMe Hidden Info - Coming soon!",
        description:
            "Get 7 chances to guess within 5% of a the sold price - without going over. Reveal more info after each guess.",
        disabled: true,
        icon: {
            src: HiddenInfoIcon,
            width: 70,
            height: 70,
        },
    },
    v5: {
        url: "puzzle",
        component: <Puzzle version="v5" />,
        gaVersion: "V5 - Puzzle",
        title: "PriceMe Puzzle",
        description: "Get 6 chances to guess the price - each guess reveals hints about the actual price",
        icon: {
            src: PuzzleIcon,
            width: 82,
            height: 63,
        },
        modal: {
            body: <PuzzleModalBody />,
            show_actual_price: true,
            hide_solved: true,
        },
        max_tries: 6,
        score_by: "tries",
    },
};
