import React, { createContext, useRef, useContext, useCallback } from "react";

// Create a context
const AnimationContext = createContext();

export const AnimationProvider = ({ children }) => {
    // Store animations in a ref
    const animations = useRef({}); // { [key]: { controls, promise, resolve } }

    // Register an animation
    const registerAnimation = useCallback((key, controls) => {
        if (animations.current[key]) return; // Avoid overwriting if already registered

        animations.current[key] = controls;
    }, []);

    // Start an animation and await its completion
    const startAnimation = useCallback(async (key, animationProps) => {
        const animation = animations.current[key];
        if (!animation) {
            console.warn(`Animation with key "${key}" not found.`);
            return;
        }

        return animation.start(animationProps);
    }, []);

    return (
        <AnimationContext.Provider
            value={{
                registerAnimation,
                startAnimation,
            }}
        >
            {children}
        </AnimationContext.Provider>
    );
};

// Custom hook to use the AnimationContext
export const useAnimationContext = () => useContext(AnimationContext);
