import OneSignalWrapper from "./components/common/onesignal-wrapper";
import { AwardsProvider } from "./context/AwardsContext";
import { GuestProvider } from "./context/GuestContext";
import ModalProvider from "./context/ModalContext";
import { ScoreProvider } from "./context/ScoreContext";
import { ReferralBonusProvider } from "./context/ReferralBonusContext";
import { ChallengeProvider } from "./context/ChallengeContext";
import { combineComponents } from "./helpers/combineComponents";
import { AnimationProvider } from "./context/AnimationContext";

/**
 * An array of React context providers used in the application.
 *
 * This array includes context providers that is used in the whole application. These providers can be used
 * collectively to manage different aspects of the application state.
 *
 * @constant
 * @type {Array<React.Component>}
 */
const providers = [
    OneSignalWrapper,
    ModalProvider,
    ReferralBonusProvider,
    ChallengeProvider,
    AwardsProvider,
    GuestProvider,
    ScoreProvider,
    AnimationProvider,
];

/**
 * Provides the application context by combining multiple context providers.
 *
 * This component combines several context providers into a single provider.
 * The providers are applied sequentially, wrapping each subsequent provider around the previous one.
 *
 * @constant
 * @type {React.Component}
 */
export const AppContextProvider = combineComponents(...providers);
