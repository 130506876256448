import Collapse from "react-bootstrap/Collapse";
import PropTypes from "prop-types";
import { useEffect, useState } from "react";
import { useQuery } from "@tanstack/react-query";
import { isNil } from "lodash";
import { isNull } from "lodash";
import { isEmpty } from "lodash";
import { getSalesData } from "../../../services/PropertyService";
import CustomButton from "../../../components/common/buttons/custom-button/custom-button";

const SalesInfo = ({ property_id, propertyStatus }) => {
    const [openSalesInfo, setOpenSalesInfo] = useState(false);

    // Reset the open sales info flag on property change
    useEffect(() => {
        setOpenSalesInfo(false);
    }, [property_id]);

    /**
     * Fetches sales data for a specific property using the 'getSalesData' function.
     * This query is used in React applications to manage and cache remote data fetching.
     *
     * @param {Object} property - The property for which sales data is to be fetched.
     * @param {number} property_id - The unique identifier of the property.
     *
     * @returns {Object} The fetched sales data for the property, or an empty object if no data is available.
     */
    const currentPropertySalesData = useQuery({
        queryKey: ["current_property_sales_data", property_id],
        queryFn: () => getSalesData(property_id),
        enabled: !isNil(property_id) && !isNull(property_id) && !isEmpty(property_id),
        placeholderData: () => {
            return {};
        },
    });

    /**
     * Calculates the updated date for a property based on sales data, or returns an empty string if the data is not available.
     *
     * @param {Object} currentPropertySalesData - The data representing sales information for a property.
     * @param {boolean} currentPropertySalesData.isFetching - Indicates if the sales data is currently being fetched.
     * @param {boolean} currentPropertySalesData.isPlaceholderData - Indicates if the sales data is a placeholder.
     * @param {Object} currentPropertySalesData.data - The actual sales data, which includes property information.
     * @param {string} currentPropertySalesData.data.property.updated_at - The updated date of the property.
     *
     * @returns {string} The updated date as a formatted string (e.g., "MM/DD/YYYY"), or an empty string if the data is not available.
     */
    const updatedDate =
        !currentPropertySalesData.isFetching &&
        !currentPropertySalesData.isPlaceholderData &&
        !isNil(currentPropertySalesData?.data?.property?.updated_at)
            ? new Date(currentPropertySalesData.data.property.updated_at).toLocaleDateString()
            : "";

    /**
     * Trims the listing ID obtained from sales data or returns an empty string if the data is not available.
     *
     * @param {Object} currentPropertySalesData - The data representing sales information for a property.
     * @param {boolean} currentPropertySalesData.isFetching - Indicates if the sales data is currently being fetched.
     * @param {boolean} currentPropertySalesData.isPlaceholderData - Indicates if the sales data is a placeholder.
     * @param {Object} currentPropertySalesData.data - The actual sales data, which includes property information.
     * @param {string} currentPropertySalesData.data.listingId - The listing ID of the property.
     *
     * @returns {string} The trimmed listing ID, or an empty string if the data is not available.
     */
    const trimmedListingID =
        !currentPropertySalesData.isFetching &&
        !currentPropertySalesData.isPlaceholderData &&
        !isNil(currentPropertySalesData?.data?.listingId)
            ? currentPropertySalesData.data.listingId.replace(/\D/g, "")
            : "";

    return (
        <>
            <Collapse in={openSalesInfo}>
                <div id="additional-details">
                    <div className="py-3">
                        {!currentPropertySalesData.isFetching &&
                        !currentPropertySalesData.isPlaceholderData &&
                        !isNil(currentPropertySalesData.data) &&
                        !isEmpty(currentPropertySalesData.data) ? (
                            <>
                                <h3 className="mb-2">
                                    <strong className="subtitle">Sales Info</strong>
                                </h3>

                                <div className="details-item d-flex justify-content-between">
                                    <span>Source:</span>
                                    <p>
                                        {currentPropertySalesData.data.originatingSystemName} as Distributed
                                        by MLS Grid #{trimmedListingID}
                                    </p>
                                </div>

                                <div className="details-item d-flex justify-content-between">
                                    <span>Listing agent:</span>
                                    <p>
                                        {currentPropertySalesData.data.agent.firstName}{" "}
                                        {currentPropertySalesData.data.agent.lastName},{" "}
                                        {currentPropertySalesData.data.office.name} (
                                        {currentPropertySalesData.data.office.contact.office})
                                    </p>
                                </div>

                                {propertyStatus === "Closed" && (
                                    <div className="details-item d-flex justify-content-between">
                                        <span>Buyer's agent:</span>
                                        <p>
                                            {currentPropertySalesData.data.sales.agent.firstName}{" "}
                                            {currentPropertySalesData.data.sales.agent.lastName},{" "}
                                            {currentPropertySalesData.data.sales.office.servingName}
                                        </p>
                                    </div>
                                )}

                                <div>
                                    <p className="sales-info-disclaimer">
                                        Details provided by{" "}
                                        {currentPropertySalesData.data.originatingSystemName} as Distributed
                                        by MLS Grid and may not match the public record. Based on information
                                        submitted to the MLS GRID as of {updatedDate} as licensed by Mike
                                        McElroy of Kale Realty. All data is obtained from various sources and
                                        may not have been verified by broker or MLS GRID. Supplied Open House
                                        Information is subject to change without notice. All information
                                        should be independently reviewed and verified for accuracy. Properties
                                        may or may not be listed by the office/agent presenting the
                                        information. Properties displayed may be listed or sold by various
                                        participants in the MLS. Some IDX listings have been excluded from
                                        this website.
                                    </p>
                                </div>
                            </>
                        ) : (
                            <div>
                                <p>Sales data not found.</p>
                            </div>
                        )}
                    </div>
                </div>
            </Collapse>

            <CustomButton
                id="toggle-details"
                dataCy="share-button"
                className="toggle-sales-info mx-auto py-1 w-100 purple-bordered"
                handleClick={() => setOpenSalesInfo(!openSalesInfo)}
                text={openSalesInfo ? "Hide Sales Info" : "Show Sales Info"}
                isDisabled={currentPropertySalesData.isFetching && currentPropertySalesData.isPlaceholderData}
                isLoading={currentPropertySalesData.isFetching && currentPropertySalesData.isPlaceholderData}
            />
        </>
    );
};

SalesInfo.propTypes = {
    property_id: PropTypes.string.isRequired,
    propertyStatus: PropTypes.string,
};

export default SalesInfo;
