import axios from "axios";

/**
 * Retrieve sales data for a property.
 *
 * @param {number} propertyId - The ID of the property for which to retrieve sales data.
 *
 * @returns {Promise} A Promise that resolves with the sales data.
 */
export const getSalesData = async (propertyId) => {
    return (await axios.get(`/api/properties/${propertyId}/sales_data`)).data;
};

/**
 * Retrieves daily guest properties for a specific year and week.
 * @param {Object} options - Options object.
 * @param {number} options.year - The year for which to retrieve daily guest properties.
 * @param {number} options.week - The week for which to retrieve daily guest properties.
 * @returns {Promise<Object>} A promise that resolves with the daily guest properties data.
 */
export const getDailyGuestProperties = async ({ year, week }) => {
    const query = new URLSearchParams({ year, week }).toString();
    return (await axios.get(`/api/properties/daily-guest-properties?${query}`)).data;
};

/**
 * Asynchronously fetches a daily property data from the server based on the current date.
 *
 * @returns {Promise} A Promise that resolves a single property data based on todays play_date.
 */
export const getLiveVariantDailyProperty = async (play_date = "") => {
    const query = new URLSearchParams({ play_date }).toString();
    return await axios.get(`/api/properties/daily?${query}`);
};

export const getNearestProperty = async ({ location = "", limit = 0, guessedProperties = [] }) => {
    const query = new URLSearchParams({ location, limit, guessed_properties: guessedProperties }).toString();
    return await axios.get(`/api/properties/nearest?${query}`);
};
