import axios from "axios";
import { filterEmptyProps } from "../helpers/helpers";

/**
 * Asynchronously fetches default area data
 *
 * @returns {Promise<Object>} A promise that resolves with the default area data.
 */
export const getDefaultArea = async () => {
    return (await axios.get(`/api/areas/default`)).data;
};

/**
 * Asynchronously fetches area data from the server based on the specified area ID.
 *
 * @param {string} areaId - The ID of the area to fetch.
 *
 * @returns {Promise<Object>} A promise that resolves with the area data.
 */
export const getAreaById = async (areaId) => {
    return (await axios.get(`/api/areas/find/${areaId}`)).data;
};

/**
 * Asynchronously fetches all areas
 *
 * @returns {Promise<Object>} A promise that resolves with all the areas.
 */
export const getAllAreas = async () => {
    return (await axios.get(`/api/areas/all`)).data;
};

/**
 * Asynchronously fetches all areas with stats
 *
 * @returns {Promise<Object>} A promise that resolves with all the areas.
 */
export const getAllAreasWithStats = async ({
    userId,
    limit,
    page,
    dateTo,
    dateFrom,
    timeZoneOffset,
    areaId,
    excludeRecents,
}) => {
    const payload = {
        user_id: userId,
        limit,
        page,
        dateTo,
        dateFrom,
        timeZoneOffset: Math.abs(timeZoneOffset),
        areaId,
        excludeRecents,
    };

    // Remove properties with empty values
    const filteredPayload = filterEmptyProps(payload);

    const query = new URLSearchParams(filteredPayload).toString();

    return (await axios.get(`/api/areas/all/stats?${query}`)).data;
};

/**
 * Asynchronously fetches all areas with stats
 *
 * @returns {Promise<Object>} A promise that resolves with all the recent areas.
 */
export const getAllRecentAreas = async ({ userId, limit, page, dateTo, dateFrom, timeZoneOffset }) => {
    const payload = {
        limit,
        page,
        dateTo,
        dateFrom,
        timeZoneOffset: Math.abs(timeZoneOffset),
    };

    // Remove properties with empty values
    const filteredPayload = filterEmptyProps(payload);

    const query = new URLSearchParams(filteredPayload).toString();

    return (await axios.get(`/api/areas/recents/${userId}?${query}`)).data;
};

/**
 * Handles area requests
 *
 * @param {string} areaName - The name of the area.
 * @param {string} zipCode - The zip code of the area.
 *
 */

export const submitAreaRequest = async ({ areaName, zipCode }) => {
    return await axios.post("/api/areas/requests/create", {
        name: areaName,
        zipcode: zipCode,
    });
};

export const searchClosestAreas = async ({ lat, lon, page = 1, limit = 10, user_id }) => {
    const query = new URLSearchParams({ lat, lon, page, limit, user_id }).toString();
    return (await axios.get(`/api/areas/search/closest?${query}`)).data;
};

/**
 * Fetches coordinates based on the given address using multiple geocoding services.
 * @param {string} address - The address string to geocode.
 * @returns {Promise<{ lat: number, lon: number }>} - The latitude and longitude coordinates.
 */
export const getCoordinates = async (address) => {
    try {
        // Try OpenStreetMap's Nominatim API
        let response = await axios.get(`https://nominatim.openstreetmap.org/search`, {
            params: { format: "json", q: address },
        });
        if (response.data.length > 0) {
            const { lat, lon } = response.data[0];
            return { lat: parseFloat(lat), lon: parseFloat(lon) };
        }
        throw new Error("OpenStreetMap failed to find the address.");
    } catch (err) {
        console.error("OpenStreetMap Error:", err.message);
        try {
            // Try Photon API if OpenStreetMap fails
            let photonResponse = await axios.get(`https://photon.komoot.io/api/`, {
                params: { q: address },
            });
            if (photonResponse.data.features.length > 0) {
                const [lon, lat] = photonResponse.data.features[0].geometry.coordinates;
                return { lat: parseFloat(lat), lon: parseFloat(lon) };
            }
            throw new Error("Photon API failed to find the address.");
        } catch (err) {
            console.error("Photon API Error:", err.message);
            try {
                // Try Geocode.xyz if Photon fails
                let geocodeResponse = await axios.get(`https://geocode.xyz/${address}`, {
                    params: { json: 1 },
                });
                if (geocodeResponse.data.lat && geocodeResponse.data.lon) {
                    return {
                        lat: parseFloat(geocodeResponse.data.lat),
                        lon: parseFloat(geocodeResponse.data.lon),
                    };
                }
                throw new Error("Geocode.xyz failed to find the address.");
            } catch (err) {
                console.error("Geocode.xyz Error:", err.message);
                throw new Error(
                    "Address not found in any geocoding service (OpenStreetMap, Photon, or Geocode.xyz)."
                );
            }
        }
    }
};
