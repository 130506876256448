import React, { createContext, useContext, useState } from "react";

const FocusContext = createContext();

export const FocusProvider = ({ children }) => {
    const [focusedIndex, setFocusedIndex] = useState(null);

    return (
        <FocusContext.Provider value={{ focusedIndex, setFocusedIndex }}>{children}</FocusContext.Provider>
    );
};

// Hook to access the FocusContext easily
export const useFocus = () => useContext(FocusContext);
